import React from 'react';
import { HandsomeSpin } from '@src/components/handsome'
import { viewType, serviveMethod } from '@src/config';
import { path as parentPath } from '../index.module';
/**
 * 描述模块namespace，应用至serviceName
 */
// export const modelName = `${parentPath}/view-registered`;
export const path = `${parentPath}/warehouse`

const rUrl = url => `/client/receiptDemand/${url}`
const sUrl = url => `/client/shipmentDemand/${url}`
const iUrl = url => `/client/inventoryManage/${url}`

export const AuthDef = {
    main: {
        id: path,
        name: '我的仓储',
        parent: parentPath,
        type: viewType.page,
        path: path, // 不填则默认id
        // serviceName: `${path}/main`, // 不填则默认id
        // method: serviveMethod.GET,
        // url: '/client/warehouse/list',
        sort: 2
    },
    export: {
        id: `${path}/export`,
        name: '收货导出',
        parent: parentPath,
        type: viewType.view,
        sort: 1,
        serviceName: `${path}/export`,
        method: serviveMethod.POST_FORM_EXPORT,
        url: rUrl('exportReceiptDemand')
    },
    shipmentExport: {
        id: `${path}/shipmentExport`,
        name: '出货导出',
        parent: parentPath,
        type: viewType.view,
        sort: 2,
        serviceName: `${path}/shipmentExport`,
        method: serviveMethod.POST_FORM_EXPORT,
        url: sUrl('exportShipmentDemand')
    },
    inventoryExport: {
        id: `${path}/inventoryExport`,
        name: '库存导出',
        parent: parentPath,
        type: viewType.view,
        sort: 3,
        serviceName: `${path}/inventoryExport`,
        method: serviveMethod.POST_FORM_EXPORT,
        url: iUrl('exportInventoryManage')
    },
}

/**
 * 描述service请求方式
 *
 * @class Service
 */
export class Service {
}

const LoadPage = React.lazy(() => import('./index.jsx'));
/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
export default function Page () {
    return (
    // Displays <Spinner> until OtherComponent loads
    <React.Suspense fallback={<HandsomeSpin />}> 
        <LoadPage />
    </React.Suspense>
  );
}