import React from 'react';
import { HandsomeSpin } from '@src/components/handsome'
import { viewType, serviveMethod } from '@src/config';
/**
 * 描述模块namespace，应用至serviceName
 */
// export const modelName = `${parentPath}/view-registered`;
export const path = `/user_info`
/** 
 * 权限描述： AuthDef => Authority Definition
*/
export const AuthDef = {
    main: {
        id: path,
        name: '个人信息',
        parent: null,
        type: viewType.page,
        path: path, // 不填则默认id
        serviceName: `${path}/main`, // 不填则默认id
        method: serviveMethod.POST,
        url: '/client/user/currentUser',
        sort: 12
    }
}

/**
 * 描述service请求方式
 *
 * @class Service
 */
export class Service {
}

const LoadPage = React.lazy(() => import('./index.jsx'));
/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
export default function Page (props) {
    return (
    <React.Suspense fallback={<HandsomeSpin />}> 
        <LoadPage {...props} />
    </React.Suspense>
  )
}