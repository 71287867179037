// import { AuthDef } from './index.module'
// import { REQUEST_METHOD } from '@src/http/http'
export default class {
    // 获取明细
    getTransportComplaintById({
        GET
    }, id) {
        const url = `/client/complaint/${id}`;
        return GET(url)
    }
}