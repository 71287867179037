import React from 'react'
import { Router, Switch, Route, Redirect } from 'dva/router'
import { app } from '@src/models'
import { connect } from 'dva'
import { ThemeProvider } from 'styled-components'
import { ConfigProvider, Spin } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import Login from '@src/pages/login'
import Main from './Main'

const theme = {
    space: [0, 6, 12, 18, 24],
    breakpoints: ['32em', '48em', '64em']
}

@connect(({ global}) => ({
    companyInfo: global.companyInfo
}))
class Ru extends React.Component {
    constructor(props) {
        super(props)
        props.dispatch({
            type: 'global/getCompanyInfo'
        })
    }
    render() {
        const { history, companyInfo } = this.props
        if (companyInfo.loading) {
            return (
                <Spin spinning={true}>
                    <div style={{ width: '100%', height: '100vh', backgroundColor: '#252530' }}>
                        
                    </div>
                </Spin>
            )
        } else if (companyInfo.loadError) {
            return (
                <div
                    onClick={() => {
                        this.props.dispatch({
                            type: 'global/getCompanyInfo'
                        })
                    }}
                    style={{ width: '100%', height: '100vh', backgroundColor: '#252530', padding: 20, textAlign: 'center', color: 'white' }}
                >
                    加载失败,点击重新加载
                </div>
            )
        }
        return (
            <Router history={history}>
                <ThemeProvider theme={theme}>
                    <Switch>
                        <Route
                            exact
                            path="/login"
                            component={Login}
                        />
                        <Route
                            path="/"
                            exact
                            render={() => (<Redirect to="/home" />)}
                        />
                        <Route
                            path="/"
                            render={(props) => (
                                <Main {...props} />
                            )}
                        />
                    </Switch>
                </ThemeProvider>
            </Router>
        )
    }
}

export default () => {
    app.router(({ history }) => {
        // history.listen((location, action) => {
        //     console.log('router listen>>>>>>>', location, action)
        // })
        // console.log('app', app)
        // const dispatch = useDispatch()
        // dispatch({
        //     type: 'global/getCompanyInfo'
        // })
        // return null
        // useState(false)
        return (
            <ConfigProvider locale={zhCN}>
                <Ru history={history} />
            </ConfigProvider>
        )
    })
}
