import React from 'react';
import { HandsomeSpin } from '@src/components/handsome'
import { viewType, serviveMethod } from '@src/config';
import { path as parentPath } from '../index.module';
/**
 * 描述模块namespace，应用至serviceName
 */
// export const modelName = `${parentPath}/view-registered`;
export const path = `${parentPath}/transport`
/** 
 * 权限描述： AuthDef => Authority Definition
*/
export const AuthDef = {
    main: {
        id: path,
        name: '我的运输',
        parent: parentPath,
        type: viewType.page,
        path: path, // 不填则默认id
        serviceName: `${path}/main`, // 不填则默认id
        method: serviveMethod.GET,
        url: '/client/transport/list',
        sort: 1
    },
    track: {
        id: `${path}/track`,
        name: '追踪',
        parent: parentPath,
        type: viewType.view,
        sort: 1
    },
    seeReceipt: {
        id: `${path}/seeReceipt`,
        name: '查看回单',
        parent: parentPath,
        type: viewType.view,
        sort: 2
    },
    evaluate: {
        id: `${path}/evaluate`,
        name: '评价',
        parent: parentPath,
        type: viewType.view,
        sort: 3
    }
}

/**
 * 描述service请求方式
 *
 * @class Service
 */
export class Service {
}

const LoadPage = React.lazy(() => import('./index.jsx'));
/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
export default function Page () {
    return (
    // Displays <Spinner> until OtherComponent loads
    <React.Suspense fallback={<HandsomeSpin />}> 
        <LoadPage />
    </React.Suspense>
  );
}