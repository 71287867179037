import React from 'react'
import { Redirect } from 'dva/router'
import { connect } from 'dva'
import TweenOne from 'rc-tween-one'
import { HandsomeSpin } from '@src/components/handsome'
import PrimaryLayout, { OrderSearchLayout } from '@src/layout'
import { getCurrentRouter, compareObjKeys } from '@src/libs/logic'
import { homeId } from '@src/config'

@connect(({ global: {
    LOGIN_TYPE,
    authErrorType,
    isOpenEnterAnim,
    companyInfo
}, loading }) => {
    return {
        LOGIN_TYPE,
        authErrorType,
        isOpenEnterAnim,
        companyInfo,
        loading: loading.effects['global/initAuth']
    }
})
class Main extends React.Component {

    timer = null
    constructor(props) {
        super(props)
        this.state={
            ananimation: {
                x: 0,
                ease: 'easeInSine',
                duration: 300,
                overflow: 'initial'
            },
            animationStyle: {
                width: '100%',
                height: '100%'
            }
        }
        document.body.style.overflow = 'hidden'
        // setTimeout(() => {
        //     document.body.style.overflow = 'inherit'
        // }, 400)
        // this.state.animationStyle = {
        //     transform: `translateX(${document.body.offsetWidth}px)`,
        //     overflow: 'hidden'
        // }
        if (!props.isOpenEnterAnim) {
            this.state.ananimation = {}
            this.state.animationStyle = {
                width: '100%',
                height: '100%'
            }
        }
        // console.log('dispatch', props.dispatch)
        // props.dispatch({
        //     type: 'global/getCompanyInfo'
        // })
    }

    shouldComponentUpdate (nextProps) {
        const keys = ['loading', 'LOGIN_TYPE']
        return compareObjKeys(nextProps, this.props, keys)
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({
                ananimation: {},
                animationStyle: {
                    width: '100%',
                    height: '100%'
                }
            }) 
            document.body.style.overflow = 'inherit'
        }, 400)
        this.init()
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    init = () => {
        try {
            const {
                LOGIN_TYPE,
                dispatch,
                history
            } = this.props
            // console.log('init', history)
            if (LOGIN_TYPE === 1) {
                const path = history && history.location ? history.location.pathname : ''
                const currentRouter = getCurrentRouter(path)
                const homeTab = getCurrentRouter(homeId)
                dispatch({
                    type: 'global/initAuth',
                    payload: {
                        homeTab,
                        initTab: currentRouter && currentRouter.id !== homeId ? currentRouter : null
                    }
                })
            }
        } catch (error) {
            console.error('初始化失败：', error)
        }
    }

    render() {
        const {
            LOGIN_TYPE,
            loading,
            authErrorType,
            dispatch
        } = this.props
        const {
            ananimation,
            animationStyle
        } = this.state
        // console.log('Main render', loading, LOGIN_TYPE, this.props)
        // return null
        if ((''+loading) === 'undefined' && LOGIN_TYPE === 1) {
            return null
        }
        const errorInfo = {
            1: '获取权限失败，点击重新获取',
            2: '当前账号无权限，请授权后点击重试'
        }
        if (LOGIN_TYPE === 0) {
            return (
                <Redirect
                    to={{
                        pathname: "/login"
                    }}
                />
            )
        }
        return (
            <TweenOne
                animation={ananimation}
                style={animationStyle}
            >
                {
                    loading ?
                        <HandsomeSpin desc='获取用户权限中' />
                    :
                    errorInfo[authErrorType] ?
                        <React.Fragment>
                            <div
                                onClick={this.init}
                                style={{
                                    // textAlign: 'center',
                                    padding: '10px'
                                }}
                            >
                                { errorInfo[authErrorType] }
                            </div>
                            {
                                authErrorType === 2 && <span className='global_table_click_text' style={{
                                    // textAlign: 'center',
                                    padding: '10px'
                                }} onClick={() => {
                                    dispatch({
                                        type: 'global/logout'
                                    })
                                }}>退出</span>
                            }
                        </React.Fragment>
                    :
                        (
                            LOGIN_TYPE === 1 ? (
                                <PrimaryLayout />
                            ) : (
                                <OrderSearchLayout />
                            )
                        )
                }
            </TweenOne>
        )
    }

}



export default Main
