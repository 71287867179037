// import { AuthDef } from './index.module'
// import { REQUEST_METHOD } from '@src/http/http'
const rUrl = url => `/client/receiptDemand/${url}`
const sUrl = url => `/client/shipmentDemand/${url}`
const iUrl = url => `/client/inventoryManage/${url}`

export default class {
    // 获取仓库名称
    getWarehouseNameList({
        GET
    }, params) {
        const url = rUrl('getWarehouseName')
        return GET(url, params)
    }
    // 获取供应商
    getWarehouseSupplier({
        GET
    }, params) {
        const url = rUrl('getSupplier')
        return GET(url, params)
    }
    // 获取仓库区域名称
    getWarehouseAreaNames({
        GET
    }, params) {
        const url = rUrl('getWarehouseAreaName')
        return GET(url, params)
    }
    // 获取收货需求统计
    getReceiptStatis({
        GET
    }, params) {
        const url = rUrl('statis')
        return GET(url, params)
    }
    // 获取收货需求列表
    getReceiptDamandList({
        GET
    }, params) {
        const url = rUrl('list')
        return GET(url, params)
    }

    // 获取出货仓库名称
    getShipmentWarehouseNameList({
        GET
    }, params) {
        const url = sUrl('getWarehouseName')
        return GET(url, params)
    }
    // 获取客户名称列表
    getShipmentDemandClients({
        GET
    }, params) {
        const url = sUrl('getClientName')
        return GET(url, params)
    }
    // 获取出货需求统计
    getShipmentStatis({
        GET
    }, params) {
        const url = sUrl('statis')
        return GET(url, params)
    }
    // 获取出货需求列表
    getShipmentDamandList({
        GET
    }, params) {
        const url = sUrl('list')
        return GET(url, params)
    }

    // 获取库存仓库名称
    getInventorywarehouseNameList({
        GET
    }) {
        const url = iUrl('getWarehouseName')
        return GET(url)
    }
    // 获取物料名称
    getMaterialName({
        GET
    }) {
        const url = iUrl('getMaterialName')
        return GET(url)
    }
    // 获取储位列表
    getInventoryStorageList({
        GET
    }) {
        const url = iUrl('getWarehouseStorageNumber')
        return GET(url)
    }
    // 获取库存供应商列表
    getInventorySupplierList({
        GET
    }) {
        const url = iUrl('getSupplier')
        return GET(url)
    }
    // 获取库存统计
    getInventoryStatis({
        GET
    }, params) {
        const url = iUrl('statis')
        return GET(url, params)
    }
    // 获取库存列表
    getInventoryList({
        GET
    }, params) {
        const url = iUrl('list')
        return GET(url, params)
    }
}