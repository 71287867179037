import React from 'react';
import { HandsomeSpin } from '@src/components/handsome'
import { 
    viewType,
    serviveMethod
} from '@src/config';
/**
 * 模块namespace definition，
 */
export const path = '/message';

/** 
 * 权限描述： AuthDef => Authority Definition
*/
export const AuthDef = {
    main: {
        id: path,
        name: '消息管理',
        parent: null,
        type: viewType.pageHide,
        path,
        serviceName: `${path}/list`,
        method: serviveMethod.POST,
        url: '/client/warning/list',
        sort: 11
    },
    noRemind: {
        id: `${path}/noRemind`,
        name: '不再提醒',
        parent: null,
        type: viewType.view,
        serviceName: `${path}/noRemind`,
        sort: 1
    },
}

/**
 * 描述service请求方式
 *
 * @class Service
 */
export class Service {
    [AuthDef.noRemind.serviceName]({ POST }, id) {
        console.log('Service', id)
        const url = `/client/warning/cancel/${id}`
        return POST(url)
    }
}

const LoadPage = React.lazy(() => import(/* webpackChunkName:"login" */ './index.jsx'));
/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
export default function Page () {
    return (
        // Displays <Spinner> until OtherComponent loads
        <React.Suspense fallback={<HandsomeSpin />}> 
            <LoadPage />
        </React.Suspense>
    );
}