import React from 'react'
import { HandsomeSpin } from '@src/components/handsome'
import { viewType, serviveMethod } from '@src/config'
import { path as parentPath } from '../index.module'

export const path = `${parentPath}/transportOrderSearch`


export const AuthDef = {
    main: {
        id: path,
        name: '订单查询',
        parent: parentPath,
        type: viewType.pageHide,
        path: path, // 不填则默认id
        serviceName: `${path}/main`, // 不填则默认id
        method: serviveMethod.GET,
        url: '/client/transport/list',
        sort: 1
    },
    track: {
        id: `${path}/track`,
        name: '追踪',
        parent: parentPath,
        type: viewType.view,
        sort: 1
    },
}

export class Service {
}

const LoadPage = React.lazy(() => import('./index.jsx'));
/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
export default function Page () {
    return (
    // Displays <Spinner> until OtherComponent loads
    <React.Suspense fallback={<HandsomeSpin />}> 
        <LoadPage />
    </React.Suspense>
  )
}