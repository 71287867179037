import React from 'react';
import { HandsomeSpin } from '@src/components/handsome'
import { viewType, serviveMethod } from '@src/config'
import { path as parentPath } from '../index.module'


export const path = `${parentPath}/abnormal`


export const AuthDef = {
    main: {
        id: path,
        name: '运输异常',
        parent: parentPath,
        type: viewType.page,
        path: path,
        serviceName: `${path}/main`,
        method: serviveMethod.POST,
        url: '/client/abnormal/list',
        sort: 3
    },
    see: {
        id: `${path}/see`,
        name: '查看',
        parent: parentPath,
        type: viewType.view,
        sort: 1,
        serviceName: `${path}/see`,
        method: serviveMethod.GET,
        url: '/client/abnormal/handle/details',
    },
}

export class Service {
    [AuthDef.see.serviceName]({ GET }, id) {
        return GET(`${AuthDef.see.url}/${id}`)
    }

    getTransportAbnormalLinkList({ GET }) {
        return GET(`/client/abnormal/link`)
    }

    getTransportAbnormalTypeList({ GET }) {
        return GET(`/client/abnormal/type`)
    }
}

const LoadPage = React.lazy(() => import('./index.jsx'));
/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
export default function Page () {
    return (
    // Displays <Spinner> until OtherComponent loads
    <React.Suspense fallback={<HandsomeSpin />}> 
        <LoadPage />
    </React.Suspense>
  );
}