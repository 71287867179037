import React from 'react';
import { HandsomeSpin } from '@src/components/handsome'
import { viewType, serviveMethod } from '@src/config'
import { path as parentPath } from '../index.module'

export const path = `${parentPath}/complaint`


export const AuthDef = {
    main: {
        id: path,
        name: '投诉建议',
        parent: parentPath,
        type: viewType.page,
        path: path, // 不填则默认id
        serviceName: `${path}/main`, // 不填则默认id
        method: serviveMethod.POST,
        url: '/client/complaint/list',
        sort: 4
    },
    add: {
        id: `${path}/add`,
        name: '新增',
        parent: parentPath,
        type: viewType.view,
        serviceName: `${path}/add`,
        url: '/client/complaint/save',
        method: serviveMethod.POST,
        sort: 1
    },
    delete: {
        id: `${path}/delete`,
        name: '删除',
        parent: parentPath,
        type: viewType.view,
        serviceName: `${path}/delete`,
        url: '/client/complaint/delete',
        method: serviveMethod.POST,
        sort: 2
    },
    // evaluate: {
    //     id: `${path}/evaluate`,
    //     name: '评价',
    //     parent: parentPath,
    //     type: viewType.view,
    //     sort: 3
    // }
}

/**
 * 描述service请求方式
 *
 * @class Service
 */
export class Service {
}

const LoadPage = React.lazy(() => import('./index.jsx'));
/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
export default function Page () {
    return (
    // Displays <Spinner> until OtherComponent loads
    <React.Suspense fallback={<HandsomeSpin />}> 
        <LoadPage />
    </React.Suspense>
  );
}