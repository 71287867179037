import React from 'react';
import { HandsomeSpin } from '@src/components/handsome'
import { viewType, serviveMethod } from '@src/config';
import { path as parentPath } from '../index.module';
/**
 * 描述模块namespace，应用至serviceName
 */
// export const modelName = `${parentPath}/view-registered`;
export const path = `${parentPath}/account`
/** 
 * 权限描述： AuthDef => Authority Definition
*/
export const AuthDef = {
    main: {
        id: path,
        name: '用户管理',
        parent: parentPath,
        type: viewType.page,
        path: path, // 不填则默认id
        serviceName: `${path}/getData`, // 不填则默认id
        method: serviveMethod.GET,
        url: '/client/user/userPage',
        sort: 3
    },
    add: {
        id: `${path}/add`,
        name: '添加用户',
        parent: parentPath,
        type: viewType.view,
        // path: path, // 不填则默认id
        serviceName: `${path}/add`, // 不填则默认id
        method: serviveMethod.POST,
        url: '/client/user',
    },
    delete: {
        id: `${path}/delete`,
        name: '删除用户',
        parent: parentPath,
        type: viewType.view,
        // path: path, // 不填则默认id
        serviceName: `${path}/delete`, // 不填则默认id
        method: serviveMethod.DELETE,
        url: '/client/user',
    },
    edit: {
        id: `${path}/edit`,
        name: '编辑用户',
        parent: parentPath,
        type: viewType.view,
        // path: path, // 不填则默认id
        serviceName: `${path}/edit`, // 不填则默认id
        method: serviveMethod.PUT,
        url: '/client/user',
    }
}

/**
 * 描述service请求方式
 *
 * @class Service
 */
export class Service {
    // 根据部门获取部门角色列表
    getRoleByDepId({ GET }, deptId) {
        return GET(`/client/role/roleList/${deptId}`)
    }
}

const LoadPage = React.lazy(() => import(/* webpackChunkName:"account" */ './index.jsx'));
/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
export default function Page () {
    return (
    // Displays <Spinner> until OtherComponent loads
    <React.Suspense fallback={<HandsomeSpin />}> 
        <LoadPage />
    </React.Suspense>
  );
}