// import { AuthDef } from './index.module'
// import { REQUEST_METHOD } from '@src/http/http'
export default class {
    // 获取统计数据
    getTransportStatis({
        POST
    }, params) {
        // console.log('a', params)
        const url = `/client/transport/statis`;
        return POST(url, params)
    }

    // 获取查看回单的列表
    getTransportReceipt({
        GET
    }, params) {
        const url = `/client/transport/getAttachment/${params.id}/${params.orderType}`
        return GET(url, params)
    }

    // 评价
    evaluateTransportOrder({
        POST
    }, params) {
        const url = `/client/transport/saveEvaluation`
        return POST(url, params)
    }

    getTransportById({
        GET
    }, id) {
        const url = `/client/transport/findById/${id}`
        return GET(url)
    }

    getTransportTrackById({
        GET
    }, id) {
        const url = `/client/transport/getInTransitInfo/${id}`
        return GET(url)
    }

    getTransportTrack({
        GET
    }, params) {
        const url = `/client/transport/trackRecord/${params.id}/${params.orderType}`
        return GET(url)
    }

    getTransportEvaluation({
        GET
    }, orderId) {
        const url = `/client/transport/showEvaluation/${orderId}`
        return GET(url)
    }
}