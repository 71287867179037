// import React from 'react';
import {
    viewType
} from '@src/config'
// import { dirname } from 'path';
// import { HandsomeSpin } from '@src/components/handsome'
// const LoadPage = React.lazy(() => import('./index.jsx'));
/**
 * 描述模块namespace，应用至serviceName
 */
// export const modelName = 'CONFIG';
export const path = '/setting';
/** 
 * 权限描述： AuthDef => Authority Definition
 */
export const AuthDef = {
    main: {
        id: path,
        name: '基础配置',
        parent: null,
        type: viewType.category,
        // serviceName: `${modelName}/getData`,
        // method: 'POST',
        // url: '',
        sort: 7
    }
}

/**
 * 描述service请求方式
 *
 * @class Service
 */
// class Service {

// }
// /** 
//  * 从AuthDef中自动导入 service api
//  */
// for (const key in AuthDef) {
//     if (AuthDef[key].serviceName && !Service.prototype[AuthDef[key].serviceName]) {
//         Service.prototype[AuthDef[key].serviceName] = (method, params) => {
//             return method[AuthDef[key].method](AuthDef[key].url, params)
//         }
//     }
// }
// export {
//     Service
// }

/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
// export default function Page() {
//     return (
//         // Displays <Spinner> until OtherComponent loads
//         <
//         React.Suspense fallback = {
//             < HandsomeSpin / >
//         } >
//         <
//         LoadPage / >
//         <
//         /React.Suspense>
//     );
// }